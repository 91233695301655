import { computed, ref } from 'vue';
export const useServices = () => {
    // added when the user adds a new service
    const extraService = ref(null);
    const localServices = ref([]);
    const services = computed(() => {
        return localServices.value;
    });
    const initData = (ConfiguratorData) => {
        if (ConfiguratorData?.services) {
            // initialize empty service object as a new field for multi-values
            const emptyService = ConfiguratorData?.services.find(item => {
                return item.multiple;
            });
            if (emptyService) {
                extraService.value = { ...emptyService };
            }
            // sort services
            localServices.value = ConfiguratorData.services.sort((a, b) => {
                if (a.multiple && !b.multiple)
                    return 1;
                if (b.multiple && !a.multiple)
                    return -1;
                else
                    return 0;
            });
        }
        if (ConfiguratorData?.selected?.services?.length) {
            ConfiguratorData.selected.services.forEach((service) => {
                let done = false;
                localServices.value.forEach((item, index) => {
                    if (done) {
                        return;
                    }
                    if (item.id !== service.id) {
                        return;
                    }
                    if (item.price !== 0 && !service.multiple) {
                        // duplicate id for non multiple service
                        return;
                    }
                    if (item.price !== 0 && service.multiple) {
                        // multiple services - push
                        localServices.value.push(service);
                        done = true;
                    }
                    if (item.price === 0) {
                        localServices.value[index] = service;
                        done = true;
                    }
                });
            });
        }
    };
    const addService = () => {
        if (!extraService.value) {
            return;
        }
        localServices.value.push(extraService.value);
    };
    const removeService = (index) => {
        if (localServices.value?.length) {
            localServices.value.splice(index, 1);
            return;
        }
    };
    return {
        services,
        addService,
        removeService,
        initData,
    };
};
