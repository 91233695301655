import { ref } from 'vue';
export const useStepper = () => {
    const currentStep = ref(0);
    const isStep = (index) => {
        return currentStep.value === index;
    };
    const setStep = (index) => {
        currentStep.value = index;
    };
    return {
        currentStep,
        isStep,
        setStep
    };
};
