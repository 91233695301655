export const emptyState = JSON.stringify({
    accessories: [],
    clicked: null,
    fitting: null,
    frame: {
        width: null,
        color: null,
        hinge: null,
        masonry: null,
    },
    glazing: null,
    glazingType: null,
    handrail: null,
    leaf: {
        glazing: null,
        width: null,
        color: null,
        hinge: null,
    },
    lockset: null,
    model: null,
    opening: null,
    peephole: null,
    placement: null,
    tape: null,
    tapeSide: null,
    name: null,
    number: null,
    services: [],
});
