import { ref } from 'vue';
export const useDoorLabel = (variant) => {
    let text = '';
    // default variant used for square door label
    if (variant === 'default') {
        text = 'Ondřej Černý';
    }
    // number variant used for rounded door label
    if (variant === 'number') {
        text = '12';
    }
    // local state
    const labelObject = ref({
        isEnabled: 0,
        text: text,
        selected: {
            font: null,
            frame: null,
            paw: null,
            type: null,
        },
        optionsEnabled: [],
        availableFonts: [],
        availableFrames: [],
        availablePaws: [],
        availableTypes: [],
    });
    const initData = (configuratorData) => {
        const key = variant === 'number' ? 'numberTag' : 'nameTag';
        const keyShort = variant === 'number' ? 'number' : 'name';
        if (!configuratorData.available[key]) {
            return;
        }
        if (configuratorData.selected[keyShort]) {
            ['font', 'frame', 'paw', 'type'].forEach((selectedKey) => {
                const selectedLabel = configuratorData.selected[keyShort]?.[selectedKey];
                if (selectedLabel) {
                    let selectedValue = 0;
                    configuratorData.available[key][selectedKey].options.forEach((option) => {
                        if (option.parameterValue === selectedLabel) {
                            selectedValue = option.id;
                        }
                    });
                    if (selectedValue) {
                        labelObject.value.selected[selectedKey] = selectedValue;
                    }
                }
            });
            if (configuratorData.selected[keyShort]?.text) {
                labelObject.value.text = configuratorData.selected[keyShort]?.text ?? '';
            }
        }
        else {
            ['font', 'frame', 'paw', 'type'].forEach((availableKey) => {
                const firstAvailableId = configuratorData.available?.[key]?.[availableKey]?.options?.[0]?.id;
                if (firstAvailableId) {
                    labelObject.value.selected[availableKey] = firstAvailableId;
                }
            });
        }
        labelObject.value.optionsEnabled = configuratorData.available[key]?.choice.options;
        labelObject.value.availableFonts = configuratorData.available[key]?.font.options;
        labelObject.value.availableFrames = configuratorData.available[key]?.frame.options;
        labelObject.value.availableTypes = configuratorData.available[key]?.type.options;
        if (variant === 'default' && configuratorData.available.nameTag?.paw?.options) {
            labelObject.value.availablePaws = configuratorData.available.nameTag.paw.options;
        }
    };
    const getLabelApi = (configuratorData) => {
        const key = variant === 'number' ? 'numberTag' : 'nameTag';
        if (!labelObject.value.isEnabled || !configuratorData.available[key]) {
            return null;
        }
        const availableFonts = configuratorData.available[key]?.font.options;
        const availableFrames = configuratorData.available[key]?.frame.options;
        const availableTypes = configuratorData.available[key]?.type.options;
        if (!availableFonts?.length || !availableFrames?.length || !availableTypes?.length) {
            return null;
        }
        // send labels instead of IDs
        let font = availableFonts[0]?.parameterValue ?? '';
        let frame = availableFrames[0]?.parameterValue ?? '';
        let type = availableTypes[0]?.parameterValue ?? '';
        let text = labelObject.value.text;
        if (labelObject.value?.selected.font) {
            const fontObject = availableFonts.find(font => font.id === labelObject.value.selected.font);
            if (fontObject?.parameterValue) {
                font = fontObject.parameterValue;
            }
        }
        if (labelObject.value?.selected.frame) {
            const frameObject = availableFrames.find(frame => frame.id === labelObject.value.selected.frame);
            if (frameObject?.parameterValue) {
                frame = frameObject.parameterValue;
            }
        }
        if (labelObject.value?.selected.type) {
            const typeObject = availableTypes.find(type => type.id === labelObject.value.selected.type);
            if (typeObject?.parameterValue) {
                type = typeObject.parameterValue;
            }
        }
        // ensure data on API
        const api = {
            font,
            frame,
            type,
            text,
        };
        // paws are sent only on default
        if (variant === 'default') {
            let availablePaws = configuratorData.available[key]?.paw?.options;
            if (!availablePaws?.length) {
                return null;
            }
            if (labelObject.value?.selected.paw) {
                const pawObject = availablePaws.find(paw => paw.id === labelObject.value.selected.paw);
                if (pawObject?.parameterValue) {
                    api.paw = pawObject.parameterValue;
                }
            }
        }
        return api;
    };
    const enableLabel = () => {
        labelObject.value.isEnabled = 1;
    };
    return {
        labelObject,
        getLabelApi,
        initData,
        enableLabel,
    };
};
