<template>
  <div>
    <!-- Main title -->
    <h2 class="text-center mb-4 text-4xl lg:text-5xl text-green-600 font-semibold">Konfigurátor dveří</h2>
    <!-- Description -->
    <div class="text-center mb-8">
      <p class="max-w-3xl mx-auto text-gray-700">
        Po vyplnění <strong>nezávazné</strong> poptávky se vám ozve zástupce pobočky a zodpoví veškeré dotazy.
      </p>
    </div>
  </div>
</template>
