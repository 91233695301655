import { ref, computed } from 'vue';
export const useAccessories = () => {
    const selectedAccessories = ref([]);
    const selectedAccessoriesQuantified = ref([]);
    const getAccessoryQuantity = (id) => {
        const itemQuantified = selectedAccessoriesQuantified.value.find((item) => item.id === id);
        if (itemQuantified) {
            return itemQuantified.quantity;
        }
        return 0;
    };
    const updateAccessoryQuantity = (payload) => {
        const itemQuantified = selectedAccessoriesQuantified.value.find((item) => item.id === payload.id);
        if (itemQuantified) {
            itemQuantified.quantity = payload.quantity;
        }
        else {
            selectedAccessoriesQuantified.value.push(payload);
        }
    };
    const selectedAccessoriesApi = computed(() => {
        const accessories = [];
        selectedAccessories.value.forEach((id) => {
            const matchingItem = selectedAccessoriesQuantified.value.find((item) => item.id === id);
            if (matchingItem) {
                accessories.push(matchingItem);
            }
        });
        return accessories;
    });
    return {
        selectedAccessories,
        selectedAccessoriesApi,
        getAccessoryQuantity,
        updateAccessoryQuantity,
    };
};
