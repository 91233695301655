import axios from 'axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useEnv } from '../../useEnv';
import { useParams } from '../../useParams';
import { useMessages } from '../../useMessages';
import { onError } from './onError';
const { addMessage } = useMessages();
const { baseUrl } = useEnv();
// axios instance
const instance = axios.create({
    baseURL: baseUrl,
});
// environment from params
const { isApp, isWeb, token } = useParams();
let apiPathInquiry = '';
if (isWeb.value) {
    // set api endpoints
    apiPathInquiry = '/configurator/create';
}
if (isApp.value) {
    // send token in headers
    instance.defaults.headers.common.Authorization = 'Bearer ' + token;
    // set api endpoints
    apiPathInquiry = '/configurator/update';
}
export const usePostInquiry = () => {
    // sync data (get products)
    const { execute, abort, data, error, isLoading, isFinished } = useAxios(apiPathInquiry, { method: 'POST' }, instance, {
        immediate: false,
        onError,
        onSuccess: () => {
            // only show success message in app
            if (isApp.value) {
                addMessage({
                    type: 'success',
                    text: 'Konfigurace byla aktualizována',
                });
            }
        }
    });
    return { execute, abort, data, error, isLoading, isFinished };
};
