export const useRulesConfigurator = ({ doorTape, selected, doorHandrail, }) => {
    // common rule for most fields
    const ruleCommon = {
        type: 'number',
        required: true,
        message: 'Prosím, zvolte variantu',
    };
    const rules = {
        placement: ruleCommon,
        model: ruleCommon,
        fitting: ruleCommon,
        opening: ruleCommon,
        frame: {
            type: 'object',
            fields: {
                width: ruleCommon,
                color: ruleCommon,
                hinge: ruleCommon,
                masonry: ruleCommon,
            },
        },
        leaf: {
            type: 'object',
            fields: {
                glazing: ruleCommon,
                width: ruleCommon,
                color: ruleCommon,
            }
        },
        tape: {
            type: 'number',
            required: true,
            validator(rule, value, callback, source, options) {
                if (doorTape.value.isEnabled && !value) {
                    return callback('Prosím, zvolte variantu');
                }
                else {
                    return callback();
                }
            }
        },
        tapeSide: {
            type: 'number',
            validator(rule, value, callback, source, options) {
                if (doorTape.value.isEnabled && !value) {
                    return callback('Prosím, zvolte variantu');
                }
                else {
                    return callback();
                }
            }
        },
        glazing: {
            type: 'number',
            validator(rule, value, callback, source, options) {
                // leaf type is glazing
                if (!selected.value.leaf.isFullFill && !value) {
                    return callback('Prosím, zvolte variantu');
                }
                else {
                    return callback();
                }
            }
        },
        glazingType: {
            type: 'number',
            validator(rule, value, callback, source, options) {
                // leaf type is glazing
                if (!selected.value.leaf.isFullFill && !value) {
                    return callback('Prosím, zvolte variantu');
                }
                else {
                    return callback();
                }
            }
        },
        handrail: {
            type: 'object',
            validator(rule, value, callback, source, options) {
                if (selected.value.fitting === 17560 && !value) {
                    return callback('Prosím, zvolte variantu');
                }
                return callback();
            },
            message: 'Prosím, zvolte variantu',
            fields: {
                length: {
                    type: 'number',
                    validator(rule, value, callback, source, options) {
                        // fitting is handle
                        if (selected.value.fitting === 17560 && !doorHandrail.value.length) {
                            return callback('Prosím, zvolte variantu');
                        }
                        else {
                            return callback();
                        }
                    }
                },
                type: {
                    type: 'number',
                    validator(rule, value, callback, source, options) {
                        // fitting is handle
                        if (selected.value.fitting === 17560 && !doorHandrail.value.type) {
                            return callback('Prosím, zvolte variantu');
                        }
                        else {
                            return callback();
                        }
                    }
                },
            },
        }
    };
    return {
        rules,
    };
};
export const useRules = (() => {
    const rulesAddress = {
        firstName: {
            type: 'string',
            required: true,
            message: 'Pole Jméno je povinné',
        },
        lastName: {
            type: 'string',
            required: true,
            message: 'Pole Příjmení je povinné',
        },
        phone: [
            {
                type: 'string',
                required: true,
                message: 'Pole Telefon je povinné'
            },
            {
                type: 'string',
                pattern: /^(\+420|421|00420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                message: 'Prosím, zadejte platné telefonní číslo',
            }
        ],
        email: [
            {
                type: 'string',
                required: true,
                message: 'Pole E-mail je povinné',
            },
            {
                type: 'string',
                // extracted from https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/email.ts
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Prosím, zadejte platný email',
            }
        ],
        city: {
            type: 'string',
            required: true,
            message: 'Pole Obec je povinné',
        },
        postalCode: {
            type: 'string',
            required: true,
            message: 'Pole PSČ je povinné',
        },
        note: {
            type: 'string',
            max: 300,
            message: 'Max. délka 300 znaků',
        }
    };
    const rulesName = {
        isEnabled: {
            type: 'number',
            required: true,
            message: 'Prosím, zvolte variantu',
        },
        text: {
            type: 'string',
            required: true,
            message: 'Prosím, zadejte text',
        }
    };
    const rulesNumber = {
        isEnabled: {
            type: 'number',
            required: true,
            message: 'Prosím, zvolte variantu',
        },
        text: {
            type: 'string',
            required: true,
            message: 'Prosím, zadejte text',
        }
    };
    return {
        rulesAddress,
        rulesName,
        rulesNumber,
    };
});
