import { useStorage } from '@vueuse/core';
export const useMessages = () => {
    // creates a shared storage for messages and allows the composable to be used in any component
    const messages = useStorage('ht-user-messages', []);
    const removeMessage = (index) => {
        messages.value.splice(index, 1);
    };
    const addMessage = (message) => {
        messages.value.push(message);
        // Set a timer to remove the message after 5 seconds
        setTimeout(() => {
            const index = messages.value.indexOf(message);
            if (index !== -1) {
                removeMessage(index);
            }
        }, 5000);
    };
    return { messages, addMessage, removeMessage };
};
