import { vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex items-center" };
const _hoisted_2 = ["id", "value", "name"];
const _hoisted_3 = ["for"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
    key: 0,
    class: "pl-2 text-sm font-normal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (($setup.selectedId) = $event)),
            id: $props.name + $props.option.id,
            value: $props.option.id,
            type: "radio",
            name: $props.name,
            class: _normalizeClass(["w-6 h-6 m-0 bg-white border border-solid focus:outline-none rounded-full cursor-pointer", [$setup.isInvalid ? 'border-red-600 ring-2 ring-offset-2 ring-red-400' : 'border-stone-400']])
        }, null, 10 /* CLASS, PROPS */, _hoisted_2), [
            [_vModelRadio, $setup.selectedId]
        ]),
        _createElementVNode("label", {
            for: $props.name + $props.option.id,
            class: "ml-4 font-semibold text-lg cursor-pointer"
        }, [
            _createElementVNode("span", { innerHTML: $setup.optionLabel }, null, 8 /* PROPS */, _hoisted_4),
            ($setup.optionPrice && !!$props.option.id)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "+" + _toDisplayString($setup.optionPrice), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_3)
    ]));
}
