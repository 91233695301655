import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "lg:flex items-center justify-center py-6 mb-0" };
const _hoisted_2 = { class: "p-0 mx-auto lg:mx-0" };
const _hoisted_3 = { class: "p-0 mx-auto mt-4 lg:mr-0 lg:ml-4 lg:mt-0" };
const _hoisted_4 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        _createElementVNode("li", _hoisted_2, [
            _createElementVNode("button", {
                class: "flex w-full min-w-64 items-center bg-white border-none rounded-full shadow-lg p-3 focus:outline-none focus:ring focus:border-blue-300",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => ($setup.currentStep = 0), ["prevent"]))
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(["w-8 h-8 shrink-0 flex items-center justify-center rounded-full", { 'bg-blue-700 text-white': $setup.currentStep === 0, 'bg-green-600 text-white': $setup.currentStep === 1 }])
                }, "1", 2 /* CLASS */),
                _createElementVNode("span", {
                    class: _normalizeClass(["flex-1 text-lg text-left px-4 font-semibold", { 'text-blue-700': $setup.currentStep === 0, 'text-green-600': $setup.currentStep === 1 }])
                }, " Konfigurace dveří ", 2 /* CLASS */)
            ])
        ]),
        _createElementVNode("li", _hoisted_3, [
            _createElementVNode("button", {
                class: "flex w-full min-w-64 items-center bg-white border-none rounded-full shadow-lg p-3 focus:outline-none focus:ring focus:border-blue-300",
                disabled: $setup.currentStep === 0,
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => ($setup.currentStep = 1), ["prevent"]))
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(["w-8 h-8 shrink-0 flex items-center justify-center rounded-full", { 'bg-blue-700 text-white': $setup.currentStep === 1, 'bg-gray-100 text-gray-500': $setup.currentStep === 0 }])
                }, "2", 2 /* CLASS */),
                _createElementVNode("span", {
                    class: _normalizeClass(["flex-1 text-lg text-left px-4 font-semibold", { 'text-blue-700': $setup.currentStep === 1, 'text-gray-500': $setup.currentStep === 0 }])
                }, " Kontaktní údaje ", 2 /* CLASS */)
            ], 8 /* PROPS */, _hoisted_4)
        ])
    ]));
}
