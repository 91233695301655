import axios from 'axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useEnv } from '../../useEnv';
import { useParams } from '../../useParams';
import { onError } from './onError';
const { baseUrl } = useEnv();
// axios instance
const instance = axios.create({
    baseURL: baseUrl,
});
// environment from params
const { isApp, isWeb, token } = useParams();
let apiPathConfigurator = '';
if (isWeb.value) {
    // set api endpoints
    apiPathConfigurator = '/configurator';
}
if (isApp.value) {
    apiPathConfigurator = '/configurator/detail';
    // send token in headers
    instance.defaults.headers.common.Authorization = 'Bearer ' + token;
}
export const usePostConfigurator = ({ onSuccess }) => {
    // sync data (get products)
    const { execute, abort, data, error, isLoading, isFinished } = useAxios(apiPathConfigurator, { method: 'POST' }, instance, {
        immediate: false,
        onSuccess,
        onError,
    });
    return { execute, abort, data, error, isLoading, isFinished };
};
