import { ref } from 'vue';
export const useDoorTape = () => {
    const optionsEnabled = [
        {
            id: 0,
            parameterValue: 'Nechci páskový dekor',
        },
        {
            id: 1,
            parameterValue: 'Chci páskový dekor',
        },
    ];
    const tapeObject = ref({
        isEnabled: 0,
        optionsEnabled,
    });
    const enableTape = () => {
        tapeObject.value.isEnabled = 1;
    };
    return {
        tapeObject,
        enableTape,
    };
};
