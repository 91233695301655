import { ref, watch, computed, toRef } from 'vue';
import axios from 'axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useMessages } from './useMessages';
// user messages
const { addMessage } = useMessages();
export const useApiAddress = (addressData) => {
    // axios
    const instance = axios.create({
        baseURL: 'https://core.ht.portadesign.cz/pimcore-graphql-webservices',
    });
    // initiate city fetch
    const { execute: fetchAddress, abort: abortAddress, data: addresses, error: errorAddress, isLoading: isLoadingAddress, } = useAxios('/app', { method: 'POST' }, instance, {
        immediate: false,
        onError: (e) => {
            if (e.code === 'ERR_CANCELED') {
                return;
            }
            addMessage({
                type: 'error',
                text: e.message.toString(),
            });
        },
    });
    // initiate zip fetch
    const { execute: fetchZip, abort: abortZip, data: zipCodes, error: errorZip, isLoading: isLoadingZip, } = useAxios('/app', { method: 'POST' }, instance, {
        immediate: false,
        onError: (e) => {
            if (e.code === 'ERR_CANCELED') {
                return;
            }
            addMessage({
                type: 'error',
                text: e.message.toString(),
            });
        },
    });
    // address filtering
    const query = `
    query GetAddress($address: String!, $type: String!) {
      getAddress(address: $address, type: $type) {
        totalCount
        edges {
          id
          number
          streetAndNumber
          street
          city
          municipality
          district
          region
          zip
          wholeAddress
        }
      }
    }
  `;
    const queryAddress = ref('');
    const queryZip = ref('');
    watch(queryAddress, () => {
        if (isLoadingAddress.value) {
            abortAddress();
        }
        fetchAddress({
            data: {
                query: query,
                variables: {
                    address: queryAddress.value,
                    type: 'MUNICIPALITY_AND_DISTRICT',
                },
            },
        });
    });
    const city = toRef(addressData, 'city');
    watch([city], () => {
        if (isLoadingZip.value) {
            abortZip();
        }
        fetchZip({
            data: {
                query: query,
                variables: {
                    address: `${city.value}`,
                    type: 'ZIP',
                },
            },
        });
    });
    const filteredAddresses = computed(() => {
        if (!queryAddress.value || !addresses.value || !addresses.value.data) {
            return [];
        }
        return addresses.value.data.getAddress.edges.map((object) => object.municipality);
    });
    const filteredZipCodes = computed(() => {
        if (!zipCodes.value?.data?.getAddress?.edges) {
            return [];
        }
        const zips = zipCodes.value.data.getAddress.edges.map((object) => object.zip);
        return zips.filter((zip) => {
            return zip.includes(queryZip.value);
        });
    });
    return {
        errorAddress,
        isLoadingAddress,
        queryAddress,
        filteredAddresses,
        errorZip,
        isLoadingZip,
        queryZip,
        filteredZipCodes,
    };
};
