import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return _withDirectives((_openBlock(), _createElementBlock("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (($setup.quantity) = $event)),
        type: "text",
        inputmode: "numeric",
        pattern: "[0-9]*",
        maxlength: "2",
        class: "block border-x border-y-0 border-gray-200 text-gray-900 text-center ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
        disabled: $props.disabled
    }, null, 8 /* PROPS */, _hoisted_1)), [
        [_vModelText, $setup.quantity]
    ]);
}
