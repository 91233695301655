import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAccessories } from '../useAccessories';
import { useDoorLabel } from '../useDoorLabel';
import { useDoorTape } from '../useDoorTape';
import { useHandrail } from '../useHandrail';
import { useSelected } from '../useSelected';
import { useServices } from '../useServices';
export const useConfiguratorStore = defineStore('configurator', () => {
    // selected
    const { selected, initData } = useSelected();
    // accessories
    const { selectedAccessories, selectedAccessoriesApi, getAccessoryQuantity, updateAccessoryQuantity } = useAccessories();
    // nameTag
    const { labelObject: doorLabel, getLabelApi: getDoorLabel, initData: initNameTag, enableLabel: enableName } = useDoorLabel('default');
    // doorNumber
    const { labelObject: doorNumber, getLabelApi: getDoorNumber, initData: initNumberTag, enableLabel: enableNumber } = useDoorLabel('number');
    // doorTape
    const { tapeObject: doorTape, enableTape } = useDoorTape();
    const clearTape = () => {
        selected.value.tape = null;
        selected.value.tapeSide = null;
    };
    // handrail
    const { handrailObject: doorHandrail, initData: initHandrail, getHandrailApi } = useHandrail();
    // services
    const { services, addService, removeService, initData: initServices, } = useServices();
    const isFirstLoad = ref(true);
    // data currently selected/entered by user
    // function to process data received from /configurator API endpoint
    // @params: configuratorData
    const processPostConfigurator = (data) => {
        Object.keys(selected.value).forEach((key) => {
            if (data.selected[key]) {
                selected.value[key] = data.selected[key];
            }
            if (key === 'accessories' && data.selected.accessories) {
                selectedAccessories.value = [];
                data.selected.accessories.forEach((item) => {
                    selectedAccessories.value.push(item.id);
                    updateAccessoryQuantity({
                        id: item.id,
                        quantity: item.quantity,
                    });
                });
            }
            // fallback for missing glazing value
            if (!data.selected.leaf?.glazing) {
                selected.value.leaf.glazing = data.available?.leaf?.glazings[0]?.id || null;
            }
        });
        // clear glazing options if no longer available on API
        if (!data.available.glazings?.length) {
            selected.value.glazing = null;
        }
        if (!data.available.glazingTypes?.length) {
            selected.value.glazingType = null;
        }
        // enable name option if set on API
        if (!!data.selected.name) {
            enableName();
        }
        // enable number option if set on API
        if (!!data.selected.number) {
            enableNumber();
        }
        // if first load, initialize local state
        if (isFirstLoad.value) {
            initNameTag(data);
            initNumberTag(data);
            initServices(data);
            isFirstLoad.value = false;
        }
        initHandrail(data, selected);
        // enable tape options if is set on API
        if (data.selected.tape || data.selected.tapeSide) {
            enableTape();
        }
    };
    return {
        doorHandrail,
        doorLabel,
        doorNumber,
        doorTape,
        selected,
        selectedAccessories,
        selectedAccessoriesApi,
        services,
        addService,
        clearTape,
        getAccessoryQuantity,
        getHandrailApi,
        getDoorLabel,
        getDoorNumber,
        initData,
        processPostConfigurator,
        removeService,
        updateAccessoryQuantity,
    };
});
