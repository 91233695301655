import { ref } from 'vue';
export const useHandrail = () => {
    const handrailObject = ref({
        length: null,
        type: null,
    });
    const getHandrailApi = () => {
        if (!handrailObject.value.length || !handrailObject.value.type) {
            return null;
        }
        return {
            length: handrailObject.value.length,
            type: handrailObject.value.type,
        };
    };
    const initData = (configuratorData, selected) => {
        if (!configuratorData?.available?.handrail) {
            return;
        }
        if (configuratorData?.selected?.handrail) {
            if (configuratorData.selected.handrail?.length) {
                handrailObject.value.length = configuratorData.selected.handrail.length;
            }
            if (configuratorData.selected.handrail?.type) {
                handrailObject.value.type = configuratorData.selected.handrail.type;
            }
        }
        else {
            ['length', 'type'].forEach((availableKey) => {
                const selectedId = configuratorData.available?.handrail?.[availableKey].find((option) => {
                    return option.selected;
                });
                if (selectedId) {
                    handrailObject.value[availableKey] = selectedId.id;
                }
            });
            selected.value.handrail = getHandrailApi();
        }
    };
    return {
        handrailObject,
        getHandrailApi,
        initData,
    };
};
