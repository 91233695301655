import { ref } from 'vue';
import { computedAsync } from '@vueuse/core';
export const useForm = () => {
    const customerData = ref({
        firstName: '',
        lastName: '',
        phone: '',
        email: '@',
        city: '',
        postalCode: '',
        note: '',
        files: [],
    });
    const filesApi = computedAsync(async () => {
        const files = [];
        const readFileAsDataURL = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event?.target?.result && typeof event.target.result === 'string') {
                        const base64String = event.target.result.split(',')[1];
                        resolve(base64String);
                    }
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        };
        customerData.value.files.forEach(async (file) => {
            try {
                const base64 = await readFileAsDataURL(file);
                files.push({
                    fileName: file.name,
                    mimeType: file.type,
                    data: base64,
                });
            }
            catch (error) {
                console.error(`Error reading file ${file.name}:`, error);
            }
        });
        return files;
    }, []);
    return {
        customerData,
        filesApi,
    };
};
