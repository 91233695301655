import { createApp } from "vue";
import { createPinia } from 'pinia';
import VueTippy from 'vue-tippy';
import VConfigurator from "./VConfigurator.vue";
const pinia = createPinia();
createApp(VConfigurator)
    .use(pinia)
    .use(VueTippy, {
    defaultProps: { trigger: 'click', interactive: true, },
}).mount('#configurator-rest');
