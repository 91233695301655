import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "relative" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (($setup.model) = $event)),
            name: "",
            id: "",
            cols: "30",
            rows: "10",
            class: _normalizeClass(["block px-4 pb-2.5 pt-6 w-full text-lg font-sans text-gray-900 border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-700 transition-colors peer", {
                    'bg-red-50 border-red-500 text-red-500 focus:border-red-500': ($props.validated || $props.inputsDirty.note) && $props.errors?.note?.length,
                    'bg-gray-50 border-green-600 focus:border-green-600': ($props.validated || $props.inputsDirty.note) && !$props.errors?.note?.length,
                    'bg-gray-50': !$props.inputsDirty.note,
                }]),
            placeholder: " ",
            onBlur: _cache[1] || (_cache[1] = ($event) => ($setup.emit('blur')))
        }, null, 34 /* CLASS, NEED_HYDRATION */), [
            [_vModelText, $setup.model]
        ]),
        _createCommentVNode(" Floating label "),
        _createElementVNode("label", {
            for: "first-name",
            class: _normalizeClass(["absolute text-lg font-semibold text-gray-500 duration-300 transform -translate-y-3.5 scale-75 top-4 z-10 origin-[0] left-4 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5", {
                    'text-red-500 peer-focus:text-red-500': ($props.validated || $props.inputsDirty.note) && $props.errors?.note?.length,
                    'text-green-600 peer-focus:text-green-600': ($props.validated || $props.inputsDirty.note) && !$props.errors?.note?.length
                }])
        }, " Poznámka k objednávce nebo místu dodání ", 2 /* CLASS */),
        _createElementVNode("div", {
            class: _normalizeClass(["opacity-0 flex items-center justify-between text-xs font-light mt-2 mb-0 peer-focus:opacity-100 transition-opacity", { 'text-red-500': $setup.model.length > $setup.maxLength }])
        }, [
            _createElementVNode("div", null, "Maximum " + _toDisplayString($setup.maxLength) + " znaků"),
            _createElementVNode("div", null, _toDisplayString($setup.model.length | 0), 1 /* TEXT */)
        ], 2 /* CLASS */)
    ]));
}
