import { useMessages } from '../../useMessages';
const { addMessage } = useMessages();
export const onError = (e) => {
    if (e?.code === 'ERR_CANCELED') {
        return;
    }
    if (e?.response?.data?.error) {
        addMessage({
            type: 'error',
            text: e.response.data.error.toString(),
        });
    }
    if (e?.message) {
        addMessage({
            type: 'error',
            text: e.message.toString(),
        });
    }
};
