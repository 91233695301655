import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id", "value", "name"];
const _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["sm:w-40 lg:w-56 p-0 mb-0 mx-1 bg-white rounded-t-lg", [
                $setup.selectedId && $setup.selectedId === $setup.option.id ? '' : 'shadow-inner'
            ]])
    }, [
        _withDirectives(_createElementVNode("input", {
            id: $setup.name + $setup.option.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (($setup.selectedId) = $event)),
            value: $setup.option.id,
            type: "radio",
            name: $setup.name,
            class: "hidden"
        }, null, 8 /* PROPS */, _hoisted_1), [
            [_vModelRadio, $setup.selectedId]
        ]),
        _createElementVNode("label", {
            for: $setup.name + $setup.option.id,
            class: _normalizeClass(["block w-full px-8 py-5 font-semibold text-lg text-center bg-white rounded-t-lg border-x border-t cursor-pointer", [
                    $setup.selectedId && $setup.selectedId === $setup.option.id
                        ? 'text-blue-700 relative z-10'
                        : 'text-gray-500'
                ]])
        }, [
            _createElementVNode("span", null, _toDisplayString($setup.optionLabel), 1 /* TEXT */)
        ], 10 /* CLASS, PROPS */, _hoisted_2)
    ], 2 /* CLASS */));
}
