import { computed } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { useMessages } from './useMessages';
export const useParams = () => {
    // init messages API
    const { addMessage } = useMessages();
    const params = useUrlSearchParams();
    const token = params.token;
    const app = params.app;
    const debug = params.debug;
    let selected = null;
    let order = 0;
    let configuration = 0;
    // get context notification
    const isApp = computed(() => {
        return !!app;
    });
    const isWeb = computed(() => {
        return !isApp.value;
    });
    try {
        order = parseInt(params.order);
        if (!order && isApp.value) {
            addMessage({
                type: 'error',
                text: 'V nastavení chybí parametr "ID objednávky".',
            });
        }
        configuration = parseInt(params.configuration);
        if (!configuration && isApp.value) {
            addMessage({
                type: 'error',
                text: 'V nastavení chybí parametr "ID konfigurace".',
            });
        }
    }
    catch (e) {
        addMessage({
            type: 'error',
            text: 'Neplatné ID objednávky nebo konfigurace.',
        });
    }
    try {
        if (params.selected) {
            selected = JSON.parse(params.selected);
        }
    }
    catch (e) {
        addMessage({
            type: 'error',
            text: 'Neplatný parametr "selected".',
        });
    }
    const isCreateConfiguration = computed(() => {
        return isApp.value && !!order && !configuration;
    });
    const isUpdateConfiguration = computed(() => {
        return isApp.value && !!order && !!configuration;
    });
    const isDebug = computed(() => {
        return !!debug;
    });
    return {
        configuration,
        isApp,
        isCreateConfiguration,
        isDebug,
        isUpdateConfiguration,
        isWeb,
        order,
        params,
        selected,
        token,
    };
};
