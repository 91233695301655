import { useStorage } from '@vueuse/core';
import { emptyState } from './configuratorEmptyState';
export const useSelected = () => {
    const selected = useStorage('configurator-store', JSON.parse(emptyState));
    const initData = (initialData) => {
        // map root values
        Object.keys(selected.value).forEach((key) => {
            if (initialData.selected[key]) {
                selected.value[key] = initialData.selected[key] ?? null;
            }
        });
        // map frame
        if (initialData.selected.frame) {
            Object.keys(initialData.selected.frame).forEach((key) => {
                if (initialData.selected.frame[key]) {
                    selected.value.frame[key] = initialData.selected.frame[key] ?? null;
                }
            });
        }
        // map leaf
        if (initialData.selected.leaf) {
            Object.keys(initialData.selected.leaf).forEach((key) => {
                if (initialData.selected.leaf[key]) {
                    selected.value.leaf[key] = initialData.selected.leaf[key] ?? null;
                }
            });
        }
    };
    return {
        selected,
        initData,
    };
};
