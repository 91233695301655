export const useNumberFormat = () => {
    const formatterCzk = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    const formatPriceCzk = (price) => {
        // format price to 2 decimal points
        price = Math.round(price);
        // i18n number format
        return formatterCzk.format(price).replace(/\s/g, '\u00A0');
    };
    return {
        formatPriceCzk,
    };
};
